<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="pageContent pageContent--flexColumn">
                <div class="pageTitleLayout">
                    <p class="pageTitle">Вопрос куратору</p>
                    <Button title="задать новый вопрос" :onClick="() => onOpenModal()" />
                </div>
                <div class="chat">
                    <div class="studyChatSideBar">
                        <div class="studyChatSideBar__active">
                            <p class="h4 colorWhite">Активные вопросы</p>
                        </div>
                        <div class="studyChatSideBar__content">
                            <div v-if="isActiveChatHistory">
                                <button
                                    v-for="item in isActiveChat"
                                    :key="item._id"
                                    :class="
                                        item._id === selectedItem._id
                                            ? 'studyChatSideBar__contentItems studyChatSideBar__contentItems--active'
                                            : 'studyChatSideBar__contentItems'
                                    "
                                    @click="() => handleClickActiveChat(item)"
                                >
                                    <p class="studyChatSideBar__contentText">
                                        {{ item.theme }}
                                    </p>
                                    <p
                                        class="text2 colorGray studyChatSideBar__contentText"
                                    >
                                        {{ item.replies[0].text }}
                                    </p>
                                    <div
                                        v-if="!item.viewedByCustomer"
                                        class="studyChatSideBar__orangeDot"
                                    ></div>
                                </button>
                            </div>
                            <div
                                v-if="!isActiveChatHistory"
                                class="studyChatSideBar__contentNoItem"
                            >
                                <p
                                    class="text colorHeather studyChatSideBar__contentNoItem_text"
                                >
                                    У вас еще нет активных вопросов
                                </p>
                            </div>
                        </div>
                        <div class="studyChatSideBar__archive">
                            <p class="h4 colorWhite">Архив</p>
                        </div>
                        <div class="studyChatSideBarContent" v-if="isArchiveChatHistory">
                            <button
                                v-for="item in isArchiveChat"
                                :key="item._id"
                                :class="
                                    item._id === selectedItem._id
                                        ? `studyChatSideBar__contentItems studyChatSideBar__contentItems--active`
                                        : `studyChatSideBar__contentItems`
                                "
                                @click="() => handleClickActiveChat(item)"
                            >
                                <p
                                    :class="
                                        `studyChatSideBar__contentText ${
                                            selectedItem.closedWithoutAnswer
                                                ? 'colorBlack'
                                                : 'colorGullGray'
                                        }`
                                    "
                                >
                                    {{ item.theme }}
                                </p>
                                <p
                                    class="text2 colorGullGray studyChatSideBar__contentText"
                                >
                                    Вопрос закрыт
                                </p>
                            </button>
                        </div>
                        <div
                            class="studyChatSideBar__contentNoItem"
                            v-if="!isArchiveChatHistory"
                        >
                            <p
                                class="text colorHeather studyChatSideBar__contentNoItem_text"
                            >
                                У вас еще нет активных вопросов
                            </p>
                        </div>
                    </div>

                    <div class="chatContent">
                        <div
                            :class="
                                !selectedItem.theme ? 'hidden' : 'chatContent__chatActive'
                            "
                            v-if="isActiveChatHistory || isArchiveChatHistory"
                        >
                            <div class="chatContent__chatHeaderContainer">
                                <div class="chatContent__chatHeader">
                                    <p class="chatContent__chatHeaderText">
                                        <span class="h4">
                                            {{ selectedItem && selectedItem.theme }}
                                        </span>
                                        <span class="text2 colorGray">
                                            {{
                                                chatMessages.length &&
                                                    normalizeDate(chatMessages[0].date)
                                            }}
                                        </span>
                                    </p>
                                    <p class="chatContent__chatHeaderText">
                                        <span class="h4 colorLynch">
                                            {{
                                                selectedItem.admins &&
                                                    selectedItem.admins[0] &&
                                                    selectedItem.admins[0].firstName
                                            }}
                                            {{
                                                selectedItem.admins &&
                                                    selectedItem.admins[0] &&
                                                    selectedItem.admins[0].lastName
                                            }}
                                        </span>
                                        <span
                                            class="text2 colorGray"
                                            v-if="
                                                selectedItem.admins &&
                                                    selectedItem.admins[0]
                                            "
                                        >
                                            Куратор
                                        </span>
                                    </p>
                                </div>
                                <img
                                    v-if="selectedItem.admins && selectedItem.admins[0]"
                                    :src="
                                        `${selectedItem.admins &&
                                            selectedItem.admins[0] &&
                                            $apiUrl.slice(0, -1) +
                                                selectedItem.admins[0].photo}`
                                    "
                                    alt="avatar"
                                    class="chatContent__chatHeaderIcon"
                                />
                            </div>
                            <div class="chatContent__chatContent">
                                <div class="customScroll chatContent__chatContentMain">
                                    <div v-for="item in chatMessages" :key="item._id">
                                        <div
                                            :class="
                                                item.support_answer
                                                    ? 'chatContent__chatContentItemAdmin'
                                                    : 'chatContent__chatContentItem'
                                            "
                                        >
                                            <div
                                                :class="
                                                    item.support_answer
                                                        ? 'chatContent__chatContentItem_couratorContainer'
                                                        : 'chatContent__chatContentItem_container'
                                                "
                                            >
                                                <p
                                                    :class="
                                                        `text2 ${
                                                            item.support_answer
                                                                ? 'colorWhite chatContent__chatContentItem_couratorText'
                                                                : 'chatContent__chatContentItem_text'
                                                        }`
                                                    "
                                                >
                                                    <span>
                                                        {{ item.text }}
                                                    </span>

                                                    <span>
                                                        <span
                                                            v-for="value in item.attachments"
                                                            v-bind:key="value._id"
                                                        >
                                                            <a
                                                                :href="
                                                                    item.support_answer
                                                                        ? oldClientURL +
                                                                          value.url.substring(
                                                                              1
                                                                          )
                                                                        : apiURL +
                                                                          value.url.substring(
                                                                              1
                                                                          )
                                                                "
                                                                target="_blank"
                                                            >
                                                                <span
                                                                    :class="
                                                                        `title1 colorWhite chatContent__chatContentItem_file${
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'jpeg'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'jpg'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'png'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'svg'
                                                                                )
                                                                                ? '--iconPurple'
                                                                                : ''
                                                                        }${
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'pdf'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'txt'
                                                                                )
                                                                                ? '--iconRed'
                                                                                : ''
                                                                        }${
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'doc'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'docx'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'rtf'
                                                                                )
                                                                                ? '--iconBlue'
                                                                                : ''
                                                                        }${
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'xls'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'xlsx'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'xlsm'
                                                                                ) ||
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    'ods'
                                                                                )
                                                                                ? '--iconGreen'
                                                                                : ''
                                                                        }`
                                                                    "
                                                                >
                                                                    {{
                                                                        value.filename
                                                                            .toLowerCase()
                                                                            .split(".")[
                                                                            value.filename
                                                                                .toLowerCase()
                                                                                .split(
                                                                                    "."
                                                                                ).length -
                                                                                1
                                                                        ]
                                                                    }}
                                                                </span>
                                                            </a>
                                                        </span>
                                                    </span>
                                                </p>
                                                <div
                                                    :class="
                                                        item.support_answer
                                                            ? 'chatContent__chatContentItemReplyAdmin'
                                                            : 'chatContent__chatContentItemReply'
                                                    "
                                                >
                                                    <p
                                                        class="chatContent__chatContentItemReply_button"
                                                    >
                                                        <img
                                                            class="chatContent__chatContentItemReply_icon"
                                                            alt="reply"
                                                            src="@/assets/img/common/reply.svg"
                                                        />
                                                        <button
                                                            class="btn-text colorDenim"
                                                            @click="
                                                                messageResponse(item.text)
                                                            "
                                                        >
                                                            Ответить
                                                        </button>
                                                    </p>
                                                    <p class="h5 colorGray">
                                                        {{ normalizeDate(item.date) }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div v-if="item.support_answer">
                                                <img
                                                    v-if="item.admin.photo"
                                                    :src="
                                                        `${$apiUrl.slice(0, -1) +
                                                            item.admin.photo}`
                                                    "
                                                    alt="avatar"
                                                    class="chatContent__chatContentMainIcon"
                                                />
                                                <img
                                                    v-else
                                                    src="@/assets/img/common/defaultAvatar.svg"
                                                    alt="avatar"
                                                    class="chatContent__chatContentMainIcon"
                                                />
                                            </div>
                                            <div v-else>
                                                <img
                                                    v-bind:src="`${avatar}`"
                                                    alt="avatar"
                                                    class="chatContent__chatContentMainIcon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    :class="
                                        selectedItem.closedWithoutAnswer === false
                                            ? 'colorFiord chatContent__chatContentHintTextContainer'
                                            : 'hidden'
                                    "
                                >
                                    <p
                                        class="colorFiord chatContent__chatContentHintText"
                                    >
                                        На обработку вашего ответа может уйти несколько
                                        часов
                                    </p>
                                </div>
                            </div>

                            <div class="chatContent__chatContentFooter">
                                <div
                                    class="text2 colorGray chatContent__chatContentFooter_text"
                                    v-if="!selectedItem.closedWithoutAnswer"
                                >
                                    <div class="chatContent__chatContentFooter_textInput">
                                        <img
                                            class="chatContent__chatContentFooter_icon"
                                            alt="clip"
                                            src="@/assets/img/common/clip.svg"
                                            @click="
                                                showResponseInputZone = !showResponseInputZone
                                            "
                                        />
                                        <div
                                            class="chatContent__chatContentFooter_inputLayout"
                                        >
                                            <input
                                                ref="message"
                                                type="text"
                                                :class="
                                                    isEmptyTheme
                                                        ? 'chatContent__chatContentFooter_input chatContent__chatContentFooter_input--error'
                                                        : 'chatContent__chatContentFooter_input'
                                                "
                                                placeholder="Введите свое сообщение"
                                                maxlength="1000"
                                                v-model="messageText"
                                                @keydown="(event) => handleKeyDown(event)"
                                            />
                                            <span
                                                class="h5 error chatContent__chatContentFooter_errorText"
                                                v-if="isEmptyTheme"
                                            >
                                                Введите текст сообщения перед отправкой
                                            </span>
                                        </div>
                                        <img
                                            class="chatContent__chatContentFooter_icon"
                                            alt="send"
                                            src="@/assets/img/common/send.svg"
                                            @click="() => onValidateInput()"
                                        />

                                        <InputZone
                                            v-if="showResponseInputZone"
                                            class="chatContent__chatContentFooter_dropzone"
                                            fileType="image/*"
                                            :receivedFiles="files"
                                            :maxFiles="5"
                                            :maxSize="5"
                                            :isError="isFileNotValid"
                                            errorText="Вы не можете прикрепить файл этого формата, попробуйте прикрепить изображение"
                                            :onFilesUpdate="(e) => updateFiles(e)"
                                        />
                                    </div>
                                </div>

                                <p
                                    v-else
                                    class="text2 colorGray chatContent__chatContentFooter_newText"
                                >
                                    <span
                                        >Спасибо за обращение. Куратор закрыл вопрос.
                                        Остались вопросы? Задайте
                                        <button
                                            class="btn-underline"
                                            @click="() => onOpenModal()"
                                        >
                                            новый вопрос
                                        </button></span
                                    >
                                </p>
                            </div>
                        </div>

                        <div
                            class="chatContent__contentMainItem"
                            v-if="!isActiveChatHistory && !isArchiveChatHistory"
                        >
                            <p class="h1 colorHeather">
                                У вас еще нет активных вопросов
                            </p>
                            <div class="chatContent__contentMainItem_button">
                                <Button
                                    title="задать новый вопрос"
                                    :onClick="() => onOpenModal()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <StudyChatModal v-if="isVisibleModal" :onClose="() => onCloseModal()" />
                <StudyChatModalAttation v-if="isVisibleModal && isVisibleModalAttation" :onClose="() => onCloseModalAttation()" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Button from "@/views/components/Button/Button";
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";
import StudyChatModal from "@/views/pages/study/studyChat/studyChatModal/studyChatModal";
import StudyChatModalAttation from "@/views/pages/study/studyChat/studyChatModal/studyChatModalAttation";
import InputZone from "@/views/components/InputZone/InputZone";
import { getDateFromDatetime, getTimeFromDatetime } from "../../../../helpers/dateFormat";

export default {
    name: "studyLesson",

    components: {
        Layout,
        SideBar,
        PageHeader,
        Button,
        ButtonVideoProblem,
        StudyChatModal,
        InputZone,
        getDateFromDatetime,
        getTimeFromDatetime,
        StudyChatModalAttation
    },

    async beforeMount() {
        if (
            !(
                this.$store.getters.getMember &&
                this.$store.getters.getMember.data &&
                this.$store.getters.getMember.data.enabled &&
                this.$store.getters.getMember.data.enabled.questions
            )
        ) {
            await this.$router.push("/" + this.$route.params.slug);
        }

        this.updateChat();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateChat();
        },
    },

    computed: {
        apiURL: function() {
            return process.env.VUE_APP_API_URL;
        },

        oldClientURL: function() {
            return process.env.VUE_APP_OLD_CLIENT_TEST_URL;
        },
        isActiveChatHistory() {
            return (
                this.$store.getters.getChat &&
                this.$store.getters.getChat.chat &&
                this.$store.getters.getChat.chat.memberQuestions &&
                this.$store.getters.getChat.chat.memberQuestions.filter(
                    (item) => item.closedWithoutAnswer === false
                ).length > 0
            );
        },

        isArchiveChatHistory() {
            return (
                this.$store.getters.getChat &&
                this.$store.getters.getChat.chat &&
                this.$store.getters.getChat.chat.memberQuestions &&
                this.$store.getters.getChat.chat.memberQuestions.filter(
                    (item) => item.closedWithoutAnswer === true
                ).length > 0
            );
        },

        isActiveChat() {
            return (
                this.$store.getters.getChat &&
                this.$store.getters.getChat.chat &&
                this.$store.getters.getChat.chat.memberQuestions &&
                this.$store.getters.getChat.chat.memberQuestions.filter(
                    (item) => item.closedWithoutAnswer === false
                )
            );
        },

        isArchiveChat() {
            return (
                this.$store.getters.getChat &&
                this.$store.getters.getChat.chat &&
                this.$store.getters.getChat.chat.memberQuestions &&
                this.$store.getters.getChat.chat.memberQuestions.filter(
                    (item) => item.closedWithoutAnswer === true
                )
            );
        },
    },

    data() {
        return {
            title: "Вопрос куратору",
            items: [
                {
                    text: "Обучение",
                    to: "/study",
                },
                {
                    text: "Вопрос куратору",
                    active: true,
                },
            ],

            isVisibleModal: false,
            isVisibleModalAttation: false,
            selectedItem: {},
            chatMessages: [],
            avatar: "",
            messageText: "",
            isEmptyTheme: false,
            showResponseInputZone: false,
            isFileNotValid: false,

            files: [],
        };
    },

    methods: {
        onOpenModal() {
            this.isVisibleModal = true;
            this.isVisibleModalAttation = true;
            this.showResponseInputZone = false;
        },

        normalizeDate(date) {
            return `${getDateFromDatetime(date)} ${getTimeFromDatetime(date)}`;
        },

        onCloseModal() {
            this.isVisibleModal = false;
            this.updateChat();
        },

        onCloseModalAttation() {
            this.isVisibleModalAttation = false;
            this.updateChat();
        },

        async handleClickActiveChat(item) {
            this.selectedItem = item;
            this.chatMessages = item.replies;

            await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/questions/" +
                    item.question_id +
                    "/viewedByCustomer"
            );

            this.updateChat();
        },

        onValidateInput() {
            if (this.messageText === "") {
                this.isEmptyTheme = true;
            } else {
                this.isEmptyTheme = false;
            }

            const filesFormats = [
                "jpeg",
                "jpg",
                "png",
                "svg",
                "JPEG",
                "JPG",
                "PNG",
                "SVG",
            ];

            const isValidFiles = this.files.map((item) =>
                filesFormats.includes(item.filename.split(".")[1])
            );

            if (isValidFiles.includes(true)) {
                this.isFileNotValid = false;
            }

            if (isValidFiles.includes(false)) {
                this.isFileNotValid = true;
            }

            // prettier-ignore
            if (this.messageText !== "" &&
                (isValidFiles.includes(true) && !isValidFiles.includes(false)) ||
                this.files.length === 0) {
                this.sendMessage();
            }
        },

        handleKeyDown(event) {
            if (event.keyCode === 13) {
                this.onValidateInput();
            }
        },

        // onDownloadFile(file) {
        //     let link = document.createElement("a");
        //     link.href = file;
        //     link.download = file.split("\\")[1];
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // },

        async updateChat() {
            const getChat = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/questions"
            );

            if (getChat && getChat.data && getChat.data.result && getChat.data) {
                await this.$store.dispatch("setChat", getChat.data);
                if (
                    getChat.data.memberQuestions.find(
                        (item) => item._id === this.selectedItem._id
                    )
                ) {
                    this.chatMessages = [
                        ...getChat.data.memberQuestions.find(
                            (item) => item._id === this.selectedItem._id
                        ).replies,
                    ];
                }
            } else {
                await this.$store.dispatch("clearChat");
            }
        },

        sendMessage: async function() {
            const data = {
                text: this.messageText,
                files: this.files,
            };

            if (this.messageText.length > 0) {
                await this.axios.post(
                    `/programs/${this.$route.params.slug}/questions/${this.selectedItem.question_id}/update`,
                    data
                );
                this.updateChat();
            }

            this.messageText = "";
            this.files = [];
        },

        pollData() {
            setInterval(() => {
                this.updateChat();
            }, 10000);
        },

        async updateFiles(passedFiles) {
            this.files = passedFiles;
        },

        messageResponse(message) {
            this.messageText = 'Ответ на сообщение: "' + message + '": ';
            this.$refs.message.focus();
        },
    },

    created() {
        this.pollData();
    },

    mounted() {
        this.avatar = this.$store.getters.getAvatar;
    },
};
</script>

<style lang="scss">
@import "./studyChat.scss";
</style>
