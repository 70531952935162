<template>
    <div class="popup-layout studyChatModal">
        <div class="popup popup--2">
            <button type="button" class="popup__close" @click="onClose"></button>
            <p class="h2 popup__title popup__title--left studyChatModal__title">
                Задать вопрос
            </p>
            <div class="form">
                <div class="formgroup">
                    <label class="formgroup__label">
                        Тема
                    </label>
                    <Input
                        type="text"
                        placeholder="Введите текст"
                        :inputValue="theme"
                        :isError="isEmptyTheme"
                        errorText="Введите тему"
                        :onChange="(e) => onChangeThemeInput(e)"
                    />
                </div>
                <div class="formgroup">
                    <label class="formgroup__label">
                        Описание
                    </label>
                    <div>
                        <TextArea
                            rows="5"
                            placeholder="Введите текст"
                            :isError="isEmptyDescription || isDescriptionLong"
                            :errorText="
                                isEmptyDescription
                                    ? 'Введите описание'
                                    : 'Вы не можете отправить более 1000 символов в этом текстовом поле'
                            "
                            :optimalTextLength="200"
                            optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                            :onChange="(e) => onChangeTextArea(e)"
                        />
                    </div>
                </div>

                <div class="formgroup">
                    <InputZone
                        :receivedFiles="filesModal"
                        fileType="image/*"
                        :maxFiles="5"
                        :maxSize="5"
                        :isError="isFileNotValid"
                        errorText="Вы не можете прикрепить файл этого формата, попробуйте прикрепить изображение"
                        :onFilesUpdate="(e) => updateFilesModal(e)"
                    />
                </div>
                
                <div class="form__submit">
                    <Button
                        title="Отправить"
                        extraClass="button"
                        :onClick="() => onValidateInput()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from "@/views/components/Input/Input";
import TextArea from "@/views/components/TextArea/TextArea";
import Button from "@/views/components/Button/Button";
import InputZone from "@/views/components/InputZone/InputZone";

export default {
    name: "studyChatModal",
    components: { Input, TextArea, Button, InputZone },
    props: ["onClose"],

    watch: {
        "$route.params.lesson_id": async function() {
            this.filesModal = [];
        },
    },

    data() {
        return {
            theme: "",
            description: "",
            isEmptyTheme: false,
            isEmptyDescription: false,
            isDescriptionLong: false,
            isFileNotValid: false,

            filesModal: [],
        };
    },

    methods: {
        onChangeThemeInput(value) {
            this.theme = value;
        },

        onChangeTextArea(value) {
            this.description = value;
        },

        onValidateInput() {
            this.isEmptyTheme = !this.theme;
            this.isEmptyDescription = !this.description;
            this.isEmptyDescription = !this.description;
            this.isDescriptionLong = this.description.length > 1000;

            const filesFormats = ["jpeg", "jpg", "png", "svg"];

            const isValidFiles = this.filesModal.map((item) =>
                filesFormats.includes(item.filename.split(".")[1])
            );

            if (isValidFiles.includes(true)) {
                this.isFileNotValid = false;
            }

            if (isValidFiles.includes(false)) {
                this.isFileNotValid = true;
            }

            if (
                this.theme !== "" &&
                this.description &&
                this.description.length < 1000 &&
                ((isValidFiles.includes(true) && !isValidFiles.includes(false)) ||
                    this.filesModal.length === 0)
            ) {
                const data = {
                    theme: this.theme,
                    description: this.description,
                };
                this.sendQuestions();
            }
        },

        sendQuestions: async function() {
            const chatBody = {
                type: "curator",
                theme: this.theme,
                text: this.description,
                files: this.filesModal,
            };
            const response = await this.axios.post(
                `/programs/${this.$route.params.slug}/questions/new`,
                chatBody
            );
            if (response && response.data && response.data.result) {
                this.onClose();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Новый вопрос эксперту успешно создан",
                    },
                });
            } else {
                if (response.data.code === "limit") {
                    this.onClose();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text:
                                "К сожалению, по правилам обучения в моменте можно иметь не более 2 активных вопросов одновременно",
                        },
                    });
                } else {
                    this.onClose();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "К сожалению не удалось создать новый вопрос эксперту",
                        },
                    });
                }
            }
        },

        async updateFilesModal(passedFilesModal) {
            this.filesModal = passedFilesModal;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "studyChatModal.scss";
</style>
