<template>
    <div class="popup-layout studyChatModal">
        <div class="popup popup--2">
            <button type="button" class="popup__close" @click="onClose"></button>
            <p class="popup__title popup__title--left studyChatModal__title">
                Важно ❗❗
            </p>
            <p class="popup__title popup__title--left studyChatModal__title">
                Студентам разрешается сделать максимум 2 обращения в день. Одно обращение - это до 5-ти небольших вопросов и до 5-ти прикрепленных картинок (если они необходимы). 
            </p>
            <p class="popup__title popup__title--left studyChatModal__title">
                👉Убедительная просьба не задавать в одном обращении больше, чем 5 вопросов, или скидывать вопросы файлом, иначе они проверяться не будут.
            </p>
            <p class="popup__title popup__title--left studyChatModal__title">
                Давайте ценить время и силы друг друга!😌
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: "studyChatModalAttation",
    props: ["onClose"],

    watch: {
        "$route.params.lesson_id": async function() {
            this.filesModal = [];
        },
    },

    data() {
        return {
            theme: "",
            description: "",
            isEmptyTheme: false,
            isEmptyDescription: false,
            isDescriptionLong: false,
            isFileNotValid: false,

            filesModal: [],
        };
    },
};
</script>

<style lang="scss" scoped>
@import "studyChatModal.scss";
</style>
